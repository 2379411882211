import React from "react"
import Layout from "../../components/layout"
import { lingo } from "../../data/student's-corner"
import { Image } from "semantic-ui-react"
import "../../styles/to-do-list.css"
import SEO from "../../components/seo"
const LingoList = ({ data }) => {
  return (
    <div>
      <Layout>
        <SEO
          title="IITR Lingo"
          description="A non-exhaustive list of words present in the vocabulary of the campus junta! Don’t try to memorise them all, you’ll definitely get savvy with these, by the end of the semester knowingly or unknowingly."
        />
        <h2 className="dark-color-head">{lingo.heading}</h2>
        <div
          className="normal-para"
          dangerouslySetInnerHTML={{ __html: lingo.content }}
        />
        <div>
          {lingo.cards.map(card => (
            <div className="todo">
              <div className="icon-head">
                <Image
                  src={require("../../images/icons/flag.png")}
                  className="flag-icon"
                />
                <p className="normal-para">
                  {" "}
                  <strong>{card.title} - </strong>
                  <span dangerouslySetInnerHTML={{ __html: card.content }} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </Layout>
    </div>
  )
}

export default LingoList
